import axios from "axios";
import auth from "./authService";

const interceptor = axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response && error.response.status !== 401) {
            return Promise.reject(error);
        }
        axios.interceptors.response.eject(interceptor);
        if (error.response && error.response.status === 401) {
            try {
                await auth.signinWithToken();
                error.response.config.headers[
                    "Authorization"
                ] = auth.getToken();
                return axios(error.response.config);
            } catch (ex) {
                auth.logout();
                return Promise.reject(error);
            }
        }
        alert("An error has occurred. Please try again.");
        return Promise.reject(error);
    }
);

const setToken = (token) => {
    axios.defaults.headers.common["Authorization"] = token;
};

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    setToken,
    axios,
};
