import http from "./httpService";
import { AWS } from "../constants/config";

const apiEndpoint = AWS.ServiceUrls.Letter;

const getGeneratedLetters = (Template, CaseNumbers, To) => {
    return http.post(apiEndpoint, {
        Template,
        CaseNumbers,
        To,
        GenerateLabels: "true"
    });
};

export default getGeneratedLetters;
