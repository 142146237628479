export const convertMillisUtcMillisMidnight = (millis) => {
    //Converts a date in milliseconds to UTC, and also sets the time to Midnight (beginning of the day)
    const dateConverted = new Date(millis);
    return Date.UTC(dateConverted.getFullYear(), dateConverted.getMonth(), dateConverted.getDate(), 0, 0, 0);
};

export const convertMillisUtcMillisEndOfDay = (millis) => {
    //Converts a date in milliseconds to UTC, and also sets the time to 11:59PM (end of the day)
    const dateConverted = new Date(millis);
    return Date.UTC(dateConverted.getFullYear(), dateConverted.getMonth(), dateConverted.getDate(), 23, 59, 59);
};
