import React, { useState } from "react";
import { Redirect, Link as RouterLink } from "react-router-dom";
import auth from "../../services/authService";
import signupService from "../../services/signupService";
import { useFormInput } from "../../hooks/inputHook";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Form from "../../components/Form";
import { validatePassword, validateEmail } from "../../Utilities/validation";
import Input from "../../components/Input";
import { Routes } from "../../constants/config";

export default function VerifyAccount() {
    const Email = useFormInput("");
    const VerificationCode = useFormInput("");
    const Password = useFormInput("");
    
    const [title, setTitle] = useState("Verify your account");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ severity: "info", message: null });
    
    const handleVerifyAccount = async (e) => {
        e.preventDefault();
        
        let error = validateEmail(Email.value);
        if (error) {
            setError({ severity: "error", message: error.message });
            return;
        }

        error = validatePassword(Password.value);
        if (error) {
            setError({ severity: "error", message: error.message });
            return;
        }

        setError({ ...error, message: null });
        setLoading(true);

        try {
            await signupService.verifyAccount(
                Email.value,
                Password.value,
                VerificationCode.value
            );
            
            window.location = Routes.Login.Path;
        } catch (ex) {
            setError({ severity: "error", message: ex.response.data.message });
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        setTitle("Verify your account");
        Email.onChange(e);
        Password.reset();
        VerificationCode.reset();
    };

    if (auth.getCurrentUser()) return <Redirect to={Routes.Dashboard.Path} />;

    return (
        <Form
            title={title}
            alert={error}
            loading={loading}
            handleSubmit={handleVerifyAccount}
        >
            <Input
                label="Email"
                type="email"
                value={Email.value}
                onChange={handleChange}
            />

            <Input
                label="New Password"
                type="password"
                value={Password.value}
                onChange={Password.onChange}
            />

            <Input
                label="Verification Code"
                type="text"
                value={VerificationCode.value}
                onChange={VerificationCode.onChange}
            />

            <Grid container>
                <Grid item xs>
                    <Link
                        component={RouterLink}
                        to={Routes.Login.Path}
                        variant="body2"
                    >
                        Return to Sign In
                    </Link>
                </Grid>
            </Grid>
        </Form>
    );
}
