import React, { useState, useEffect, useContext } from "react";
import { UserRoles, Routes } from "../../constants/config";
import { Redirect, useParams } from "react-router-dom";
import CustomizeTable from "../../components/CustomizeTable";
import UserContext from "../../context/UserContext";
import { Button } from "@material-ui/core";
import template from "../../services/templateService";
import { useSnackbar } from "notistack";

const Users = () => {
    let { LawFirmId } = useParams();
    const [loading, setLoading] = useState(true);
    const currentUserRole = useContext(UserContext)["custom:UserRole"];
    const [state, setState] = useState({
        columns: [
            {
                title: "NAME",
                field: "Name",
            },
            {
                title: "PATH",
                field: "Path",
                editComponent: (props) => (
                    <input
                        type="file"
                        onChange={(e) => props.onChange(e.target.files[0])}
                    />
                ),
            },
        ],
        data: [],
    });
    const { enqueueSnackbar: snackbar } = useSnackbar();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                
                let { data } = await template.getTemplates();
                if (LawFirmId) {
                    data = data.filter(
                        (template) => template.LawFirmId === LawFirmId
                    );
                }
                setState((state) => {
                    return { ...state, data };
                });
            } catch (ex) {
                
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [LawFirmId]);

    const handleAdd = async (newData) => {
        try
        {
            await new Promise((resolve) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(newData.Path);
                fileReader.onload = async () => {
                    const { data: Path } = await template.uploadTemplate(
                        `MailTemplates/${newData.Path.name}`,
                        fileReader.result.split(",")[1]
                    );
                    const { data: TemplateId } = await template.createTemplate(
                        newData.Name,
                        Path
                    );
                    newData.Path = Path;
                    newData.TemplateId = TemplateId;
                    setState((prevState) => {
                        const data = [...prevState.data];
                        data.push(newData);
                        return { ...prevState, data };
                    });

                    snackbar("Template saved successfully", { variant: "success" });    
                    resolve();
                };
            });
        } 
        catch (ex) {
            snackbar("Error occured while saving the template", { variant: "error" });
        } 
        finally {
            setLoading(false);
        }
    };

    const handleDelete = async (oldData) => {
        try {
            setLoading(true);
            await template.removeTemplate(oldData.TemplateId);
            await template.deleteTemplate(oldData.Path);
            snackbar("Template deleted", { variant: "success" });
            setState((prevState) => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
            });
        } 
        catch (ex) {
            snackbar("Error occured while deleting the template", { variant: "error" });
        }
        finally {
            setLoading(false);
        }
    };

    if (currentUserRole === UserRoles.NormalUser) {
        return <Redirect to={Routes.Dashboard.Path} />;
    }

    return (
        <>
            <h2>Manage Law Firm Templates</h2>
            <p>To add a mail template to the law firm click the [+] button.</p>
            <p>
                Admin accounts will be able to manage templates, users, and
                search the Leads database. User accounts only have access to
                search Leads.
            </p>
            <p>
                New users will receive an email to complete their registration
                and set their password.
            </p>
            <CustomizeTable
                title="Manage Mail Templates"
                columns={state.columns}
                data={state.data}
                isLoading={loading}
                editable={{
                    onRowAdd: (newData) => handleAdd(newData),
                    onRowDelete: (oldData) => handleDelete(oldData),
                }}
                options={{
                    search: true,
                    actionsColumnIndex: -1,
                }}
                localization={{
                    body: {
                        editRow: {
                            deleteText:
                                "Are you sure you want to delete this mail template?",
                        },
                    },
                }}
            />

            {currentUserRole === UserRoles.SuperAdmin && (
                <Button variant="contained" href={Routes.LawFirms.Path}>
                    Return to Law Firms
                </Button>
            )}
        </>
    );
};

export default Users;
