import http from "./httpService";
import { AWS } from "../constants/config";

const apiEndpoint = AWS.ServiceUrls.LawFirms;

const getFirm = (FirmId) => {
    return http.get(apiEndpoint + FirmId);
};

const getFirms = () => {
    return http.get(apiEndpoint);
};

const updateFirm = (firm) => {
    return http.put(apiEndpoint + firm.LawFirmId, firm);
};

const deleteFirm = (firm) => {
    return http.delete(apiEndpoint + firm.LawFirmId);
};

const createFirm = (firm) => {
    return http.post(apiEndpoint, firm);
};

export default {
    getFirm,
    getFirms,
    updateFirm,
    deleteFirm,
    createFirm,
};
