import http from "./httpService";
import { AWS } from '../constants/config';
import jwtDecode from 'jwt-decode';

const tokenKey = "token";

http.setToken(getToken());

export async function signin(Username, Password) {
    const { data: token } = await http.post(AWS.ServiceUrls.SignIn, { Username, Password });
    localStorage.setItem(tokenKey, JSON.stringify(token));
}

export function getCurrentUser() {
    try {
        const token = JSON.parse(localStorage.getItem(tokenKey));
        return jwtDecode(token.idToken.jwtToken);
    } catch (ex) {
        return null;
    }
}

export async function signinWithToken() {
    const oldToken = JSON.parse(localStorage.getItem(tokenKey));
    const email = oldToken.idToken.payload.email;
    const refreshToken = oldToken.refreshToken;
    const { data: token } = await http.put(AWS.ServiceUrls.SignIn, { Username: email, refreshToken });
    localStorage.setItem(tokenKey, JSON.stringify(token));
    http.setToken(getToken());
}

export function getToken() {
    try {
        const token = JSON.parse(localStorage.getItem(tokenKey));
        return token.idToken.jwtToken;
    } catch (ex) {
        return null;
    }
}

export function logout() {
    localStorage.clear(tokenKey);
}

export default {
    signin,
    getCurrentUser,
    signinWithToken,
    getToken,
    logout
};
