import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { validateProperty } from "../../Utilities/validation";

export default function CustomTextField(props) {
    const [error, setError] = useState(false);

    useEffect(() => {
        const validationError = validateProperty(
            props.columnDef.field,
            props.value
        );
        if (validationError) {
            setError(true);
        } else {
            setError(false);
        }
    }, [props]);

    return (
        <TextField
            error={error}
            value={props.value ? props.value : ""}
            placeholder={props.columnDef.title}
            inputProps={{ style: { fontSize: 13 } }}
            onChange={(e) => props.onChange(e.target.value)}
        />
    );
}
