import Joi from "@hapi/joi";

const schema = {
    Email: Joi.string()
        .trim()
        .required()
        .email({ minDomainSegments: 2, tlds: false })
        .message("Please enter a valid email address")
        .label("Email"),
    Password: Joi.string()
        .trim()
        .required()
        .min(8)
        .label("Password")
        .pattern(new RegExp("(?=.*[a-z])"))
        .message("Password must contain a lower case letter")
        .pattern(new RegExp("(?=.*[A-Z])"))
        .message("Password must contain an upper case letter")
        .pattern(new RegExp("(?=.*[0-9])"))
        .message("Password must contain a number")
        .pattern(new RegExp('[!@#$%^&*(),.?":{}|<>]'))
        .message("Password must contain a special character")
        .label("Password"),
};

const userSchema = {
    FirstName: Joi.string().required().label("First Name"),
    LastName: Joi.string().required().label("Last Name"),
    Email: Joi.string()
        .trim()
        .required()
        .email({ minDomainSegments: 2, tlds: false })
        .label("Email"),
    Phone: Joi.string()
        .required()
        .label("Phone")
        .min(10)
        .max(15)
        .message("Phone number is invalid"),
    UserRole: Joi.string().trim().required().label("Access Level"),
};

const options = { abortEarly: true };

export const validateEmail = (Email) => {
    const { error } = schema.Email.validate(Email, options);
    return error;
};

export const validatePassword = (Password) => {
    const { error } = schema.Password.validate(Password, options);
    return error;
};

export const validate = (Email, Password) => {
    let error = validateEmail(Email);
    if (!error) {
        error = validatePassword(Password);
    }
    return error;
};

export const validateProperty = (name, value) => {
    const { error } = userSchema[name].validate(value);
    return error;
};

export const validateUser = (userData) => {
    for (var key in userSchema) {
        const error = validateProperty(key, userData[key]);
        if (error) {
            return error;
        }
    }
    return null;
};
