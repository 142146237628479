import React, { useContext } from "react";
import { SearchBar, SearchResults } from "./components";
import getLeads from "../../services/leadsService";
import firm from "../../services/firmsService";
import UserContext from "../../context/UserContext";
import { Redirect } from "react-router-dom";
import { convertMillisUtcMillisMidnight, convertMillisUtcMillisEndOfDay } from "../../Utilities/dateUtilities";
import AvailableCounties from './components/SearchBar/AvailableCounties';

const Leads = () => {
    const LawFirmId = useContext(UserContext)["custom:FirmId"];
    const [searchResults, setSearchResults] = React.useState([]);
    const [fromDate, setFromDate] = React.useState(
        new Date().setDate(new Date().getDate() - 1)
    );
    const [toDate, setToDate] = React.useState(new Date());
    const [loading, setLoading] = React.useState(false);
    const [allCounties] = React.useState(AvailableCounties);
    const [counties, setCounties] = React.useState([]);
    const [county, setCounty] = React.useState("");
    const [caseTypes, setCaseTypes] = React.useState([]);
    const [leadToInclude, setLeadToInclude] = React.useState(1);
    const [subscriptions, setSubscriptions] = React.useState([]);
    const [courtTypesSubscriptions, setCourtTypesSubscriptions] = React.useState([]);
    const [courtType, setCourtType] = React.useState("");
    const [leadRole, setLeadRole] = React.useState("");
    
    const handleCounties = (event) => {
        setCounty(event);

        var sub = subscriptions.find(s => s.startsWith(event));
        const courtTypes = sub.split("|")[1].split(",");
        setCourtTypesSubscriptions(courtTypes);

        if (courtTypes.length === 1){
            setCourtType(courtTypes[0]);
            setLeadRole(courtTypes[0] === "Criminal" ? "Defendant" : "Petitioner"); 
        }
    };

    const handleCourtType = (event) => {
        setCourtType(event);
        setLeadRole(event === "Criminal" ? "Defendant" : "Petitioner"); 
    };

    const handleCaseTypes = (event) => {
        const allCaseTypes = caseTypes.slice();
        const index = allCaseTypes.findIndex(
            (caseType) => caseType.title === event.target.name
        );
        allCaseTypes[index].checked = event.target.checked;
        setCaseTypes(allCaseTypes);
    };

    React.useEffect(() => {
        if (county && courtType){ 
            setCaseTypes(
                allCounties
                    .find(c => c.CountyName === county).CaseTypes
                    .filter(cs => cs.CourtType === courtType)
                    .map(cs => {return {...cs, checked: false}}));
        }
    }, [allCounties, courtType, county]);

    React.useEffect(() => {
        const fetchCounties = async () => {
            const { data } = await firm.getFirm(LawFirmId);
            setSubscriptions(data.Subscriptions);
            
            setCounties(
                data.Subscriptions.map((subscription) => ({
                    title: subscription.split("|")[0],
                    value: subscription.split("|")[0]
                }))
            );
        };
        fetchCounties();
    }, [LawFirmId]);

    React.useEffect(() => {
        const fetchSearchResults = async () => {
            setLoading(true);
            if (county && courtType) {
                try {
                    const { data } = await getLeads(
                        convertMillisUtcMillisMidnight(fromDate.getTime()),
                        convertMillisUtcMillisEndOfDay(toDate.getTime()),
                        county,
                        courtType,
                        caseTypes
                            .filter((caseType) =>
                                caseType.checked ? caseType.title : null
                            )
                            .map((caseType) => caseType.title)
                            .join(),
                        leadToInclude,
                        leadRole
                    );
                    
                    setSearchResults(data);
                } catch (ex) {
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchSearchResults();
    }, [fromDate, toDate, caseTypes, leadRole, leadToInclude, courtType, county]);

    if (!LawFirmId) {
        return <Redirect to="/Dashboard" />;
    }

    return (
        <>
            <p>Select from the filters below to search for leads.</p>
            <SearchBar
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                counties={counties}
                handleCounties={handleCounties}
                selectedCounty={county}
                courtTypes={courtTypesSubscriptions}
                handleCourtTypes={handleCourtType}
                selectedCourtType={courtType}
                caseTypes={caseTypes}
                handleCaseTypes={handleCaseTypes}
                leadRole={leadRole}
                setLeadRole={setLeadRole}
                leadToInclude={leadToInclude}
                setLeadToInclude={setLeadToInclude}
            />
            <SearchResults
                data={searchResults}
                loading={loading}
                leadRole={leadRole}
                county={county}
                courtType={courtType}
            />
        </>
    );
};

export default Leads;
