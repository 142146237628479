import React from "react";
import MaterialTable from "material-table";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import green from "@material-ui/core/colors/green";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

// import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    success: {
        color: green[500],
    },
}));

export default function CustomizeTable(props) {
    const classes = useStyles();

    return (
        <MaterialTable
            {...props}
            icons={{
                Delete: () => <DeleteOutlineIcon color="error" />,
                Edit: () => <EditOutlinedIcon color="secondary" />,
                Check: () => <CheckOutlinedIcon className={classes.success} />,
                Clear: () => <ClearOutlinedIcon color="error" />,
            }}
        />
    );
}

// export default CustomizeTable;
