import React, { useState, useEffect, useContext } from "react";
import user from "../../services/userService";
import signup from "../../services/signupService";
import { UserRoles, Routes } from "../../constants/config";
import { Redirect, useParams } from "react-router-dom";
import { validateUser } from "../../Utilities/validation";
import CustomTextField from "../../components/CustomizeField";
import CustomizeTable from "../../components/CustomizeTable";
import UserContext from "../../context/UserContext";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";

const Users = () => {
    let { LawFirmId } = useParams();
    const [loading, setLoading] = useState(true);
    const currentUserRole = useContext(UserContext)["custom:UserRole"];
    const { enqueueSnackbar: snackbar } = useSnackbar();
    const [state, setState] = useState({
        columns: [
            {
                title: "FIRST NAME",
                field: "FirstName",
                editComponent: (props) => <CustomTextField {...props} />,
            },
            {
                title: "LAST NAME",
                field: "LastName",
                editComponent: (props) => <CustomTextField {...props} />,
            },
            {
                title: "EMAIL",
                field: "Email",
                editComponent: (props) => <CustomTextField {...props} />,
            },
            {
                title: "PHONE",
                field: "Phone",
                editComponent: (props) => <CustomTextField {...props} />,
            },
            {
                title: "ACCESS LEVEL",
                field: "UserRole",
                lookup: {
                    NormalUser: "User",
                    Admin: "Admin",
                },
                initialEditValue: "NormalUser",
            },
        ],
        data: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                let { data } = await user.getUsers();
                if (LawFirmId) {
                    data = data.filter((user) => user.LawFirmId === LawFirmId);
                }
                setState((state) => {
                    return { ...state, data };
                });
            } catch (ex) {
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [LawFirmId]);

    const handleUpdate = async (newData, oldData) => {
        const validationError = validateUser(newData);
        if (validationError) {
            return Promise.reject();
        }
        try {
            await user.updateUser(newData);
            
            setState((prevState) => {
                const data = [...prevState.data];
                data[data.indexOf(oldData)] = newData;
                return { ...prevState, data };
            });

            snackbar("User updated successfully", { variant: "success" });    
        } catch (ex) {
            snackbar("An error occurred while saving user", { variant: "error" });    
        }
    };

    const handleAdd = async (newData) => {
        const validationError = validateUser(newData);
        if (validationError) {
            return Promise.reject();
        }
        try {
            const { data } = await signup.newUser(
                newData.Email,
                newData.UserRole,
                LawFirmId
            );
            newData.UserId = data.UserSub;
            if (LawFirmId) {
                newData.LawFirmId = LawFirmId;
            }
            await user.addUser(newData);
            setState((prevState) => {
                const data = [...prevState.data];
                data.push(newData);
                return { ...prevState, data };
            });

            snackbar("User created successfully", { variant: "success" });    
        } catch (ex) {
            snackbar("Error occured while adding user", { variant: "error" });    
        }
    };

    const handleDelete = async (oldData) => {
        try {
            await user.deleteUser(oldData);
            setState((prevState) => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
            });

            snackbar("User deleted", { variant: "success" });    
        } catch (ex) {
            snackbar("Error deleting user", { variant: "error" });    
        }
    };

    if (currentUserRole === UserRoles.NormalUser) {
        return <Redirect to={Routes.Dashboard.Path} />;
    }

    return (
        <>
            <h2>Manage Law Firm Users</h2>
            <p>To add a user to the law firm click the [+] button.</p>
            <p>
                Admin accounts will be able to manage templates, users, and
                search the Leads database. User accounts only have access to
                search Leads.
            </p>
            <p>
                New users will receive an email to complete their registration
                and set their password.
            </p>
            <CustomizeTable
                title="Manage Users"
                columns={state.columns}
                data={state.data}
                isLoading={loading}
                editable={{
                    isEditable: (rowData) =>
                        rowData.UserRole === "Admin" ? false : true,
                    isDeletable: (rowData) =>
                        rowData.UserRole === "Admin" ? false : true,
                    onRowAdd: (newData) => handleAdd(newData),
                    onRowUpdate: (newData, oldData) =>
                        handleUpdate(newData, oldData),
                    onRowDelete: (oldData) => handleDelete(oldData),
                }}
                options={{
                    search: true,
                    actionsColumnIndex: -1,
                }}
                localization={{
                    body: {
                        editRow: {
                            deleteText:
                                "Are you sure you want to delete this user?",
                        },
                    },
                }}
            />

            {currentUserRole === UserRoles.SuperAdmin && (
                <Button variant="contained" href={Routes.LawFirms.Path}>
                    Return to Law Firms
                </Button>
            )}
        </>
    );
};

export default Users;
