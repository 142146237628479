import React from "react";
import clsx from "clsx";
import Logo from "../../../../assets/img/logo.png";
import { Drawer, IconButton, Divider, List } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SideNav from "./components/SideNav";

const Sidebar = (props) => {
    const { open, classes, handleDrawerClose } = props;
    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(
                    classes.drawerPaper,
                    !open && classes.drawerPaperClose
                ),
            }}
            open={open}
        >
            <div className={classes.toolbarIcon}>
                <img
                    className={classes.sidebarLogo}
                    alt="Admin Dashboard"
                    src={Logo}
                />
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <List>
                <SideNav />
            </List>
            <Divider />
        </Drawer>
    );
};

export default Sidebar;
