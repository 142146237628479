import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { Routes } from "../../constants/config";
import UserContext from "../../context/UserContext";

const ProtectedRoute = ({ children, ...rest }) => {
    const currentUser = useContext(UserContext);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                currentUser ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: Routes.Login.Path,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default ProtectedRoute;
