import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Button from "@material-ui/core/Button";

import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
    },
    dropdown: {
        position: "absolute",
        top: 40,
        right: 0,
        left: 0,
        zIndex: theme.zIndex.mobileStepper,
        width: 255,
        whiteSpace: "nowrap",
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        color: "black",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
            // width:"70vw",
            // right: 4,
        },
    },
    plane: {
        textTransform: "capitalize",
        color: "inherit",
        borderColor: "inherit",
        marginBottom: 5,
    },
    arrow: {
        borderColor: "transparent transparent #fff",
        borderStyle: "solid",
        borderWidth: "0 8px 8px",
        display: "inline-block",
        position: "absolute",
        top: "-8px",
        left: 30,
        "-webkit-transform": "translate(-50%,0)",
        transform: "translate(-50%,0)",
        content: "",
    },
}));

export default function ClickAway(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.root}>
                <Button
                    type="button"
                    variant="outlined"
                    className={classes.plane}
                    startIcon={props.startIcon}
                    onClick={handleClick}
                >
                    {props.title}
                </Button>
                {open ? (
                    <Box
                        boxShadow={3}
                        bgcolor="background.paper"
                        p={1}
                        className={classes.dropdown}
                    >
                        <span className={classes.arrow}></span>
                        {props.children}
                    </Box>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}
