import http from "./httpService";
import { AWS } from "../constants/config";

const apiEndpoint = AWS.ServiceUrls.Leads;

const getLeads = (
    fromData,
    toDate,
    county,
    courtType,
    caseTypes,
    leadToInclude,
    leadRole
) => {
    return http.get(apiEndpoint, {
        params: {
            StartDate: fromData,
            EndDate: toDate,
            County: county,
            CourtType: courtType,
            CaseType: caseTypes,
            LeadsToInclude: leadToInclude,
            LeadRole: leadRole,
        },
    });
};

export default getLeads;
