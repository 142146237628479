import React, { useContext } from "react";
import UserContext from "../../context/UserContext";
import { UserRoles } from "../../constants/config";

const Dashboard = () => {
    const currentUser = useContext(UserContext);
    const currentUserRole = currentUser["custom:UserRole"];

    return (
        <div>
            <h2>Dashboard</h2>
            {
                currentUserRole === UserRoles.SuperAdmin &&
                <p>To manage law firms, click 'Law Firms' from the menu.</p>
            }

            {
                currentUserRole !== UserRoles.SuperAdmin &&
                <p>To manage the users for your law firm, click 'Users' from the menu.</p>
            }
        </div>
    );
};

export default Dashboard;
