import http from "./httpService";
import { AWS } from "../constants/config";

const apiEndpoint = AWS.ServiceUrls.MailTemplates;

const getTemplates = () => {
    return http.get(apiEndpoint.Dynamo);
};

const uploadTemplate = (key, base64String) => {
    return http.post(apiEndpoint.S3, { key, base64String });
};

const createTemplate = (Name, Path) => {
    return http.post(apiEndpoint.Dynamo, { Name, Path });
};

const removeTemplate = (templateID) => {
    return http.delete(apiEndpoint.Dynamo + templateID);
};

const deleteTemplate = (key) => {
    return http.delete(apiEndpoint.S3, { params: { key } });
};

export default {
    getTemplates,
    uploadTemplate,
    createTemplate,
    removeTemplate,
    deleteTemplate,
};
