import React from "react";
import Routes from "./Routes";
import Snackbar from "./components/Snackbar";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        secondary: {
            main: "#5383ff",
            contrastText: "#fff",
        },
        primary: {
            main: "#3d4977",
            contrastText: "#fff",
        },
    },
    status: {
        danger: "orange",
    },
    typography: {
        fontSize: 12,
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Snackbar>
                <Routes />
            </Snackbar>
        </ThemeProvider>
    );
}

export default App;
