import React, { useEffect, useState, useContext } from "react";
import firm from "../../services/firmsService";
import CustomizeTable from "../../components/CustomizeTable";
import { UserRoles, Routes } from "../../constants/config";
import { Redirect, useHistory } from "react-router-dom";
import UserContext from "../../context/UserContext";

const LawFirms = () => {
    const [loading, setLoading] = useState(true);
    const currentUserRole = useContext(UserContext)["custom:UserRole"];
    let history = useHistory();
    const [state, setState] = React.useState({
        columns: [
            { title: "LAW FIRM", field: "FirmName" },
            { title: "ADDRESS", field: "Address" },
            { title: "PHONE", field: "Phone", type: "string" },
            {
                title: "SUBSCRIPTIONS",
                field: "Subscriptions",
            },
            {
                title: "ACTIVE",
                field: "IsActive",
                type: "boolean",
            },
        ],
        data: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                let { data } = await firm.getFirms();
                data = data.map((lawFirm) => ({
                    ...lawFirm,
                    Subscriptions: lawFirm.Subscriptions.join(", "),
                }));
                setState((state) => {
                    return { ...state, data };
                });
            } catch (ex) {
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleUpdate = async (newData, oldData) => {
        try {
            const data = {
                ...newData,
                Subscriptions: newData.Subscriptions.split(
                    ","
                ).map((subscription) => subscription.trim()),
            };
            await firm.updateFirm(data);
            setState((prevState) => {
                const data = [...prevState.data];
                data[data.indexOf(oldData)] = newData;
                return { ...prevState, data };
            });
        } catch (ex) {}
    };

    const handleAdd = async (newData) => {
        try {
            newData.Subscriptions = newData.Subscriptions.split(",");
            await firm.createFirm(newData);
            setState((prevState) => {
                const data = [...prevState.data];
                data.push(newData);
                return { ...prevState, data };
            });
        } catch (ex) {}
    };

    const handleDelete = async (oldData) => {
        try {
            await firm.deleteFirm(oldData);
            setState((prevState) => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
            });
        } catch (ex) {}
    };

    if (currentUserRole !== UserRoles.SuperAdmin) {
        return <Redirect to={Routes.Dashboard.Path} />;
    }

    return (
        <>
            <h2>Manage Law Firms</h2>
            <p>
                To add a new law firm, click the [+] button. To manage the users
                for a law firm, click the people icon under actions for the law
                firm.
            </p>
            <CustomizeTable
                title="Manage Law Firms"
                columns={state.columns}
                data={state.data}
                isLoading={loading}
                editable={{
                    onRowAdd: (newData) => handleAdd(newData),
                    onRowUpdate: (newData, oldData) =>
                        handleUpdate(newData, oldData),
                    onRowDelete: (oldData) => handleDelete(oldData),
                }}
                actions={[
                    {
                        icon: "group",
                        tooltip: "Manage Users",
                        onClick: (event, rowData) => {
                            history.push(
                                `/LawFirms/${rowData.LawFirmId}/ManageUsers`
                            );
                        },
                    },
                    {
                        icon: "file_copy",
                        tooltip: "Manage Templates",
                        onClick: (event, rowData) => {
                            history.push(
                                `/LawFirms/${rowData.LawFirmId}/ManageTemplates`
                            );
                        },
                    },
                ]}
                options={{
                    search: true,
                    actionsColumnIndex: -1,
                }}
                localization={{
                    body: {
                        editRow: {
                            deleteText:
                                "Are you sure you want to delete this law firm?",
                        },
                    },
                }}
            />
        </>
    );
};

export default LawFirms;
