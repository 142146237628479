import React from "react";
import CustomizeTable from "../../../../components/CustomizeTable";
import template from "../../../../services/templateService";
import getLetter from "../../../../services/letterService";
import SaveIcon from "@material-ui/icons/Save";
import SearchResultColumns from "./SearchResultColumns";
import { useSnackbar } from "notistack";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    MenuItem,
    CircularProgress,
} from "@material-ui/core";

const FormDialog = ({ open, handleClose, templates, caseNumbers, leadRole }) => {
    const [download, setDownload] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [template, setTemplate] = React.useState("");
    const { enqueueSnackbar: snackbar } = useSnackbar();

    const generateMail = async () => {
        setLoading(true);
        setDownload(null);
        try {
            const { data } = await getLetter(template, caseNumbers, leadRole);
            setDownload(data);
            snackbar("Success! Click Save to download the file", { variant: "success" });
        } catch (ex) {
            snackbar("Error occured while generating mail", { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    const closeDialog = () => {
        setDownload(false);
        setTemplate(false);
        handleClose();
    };

    const handleChange = (event) => {
        setTemplate(event.target.value);
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Mail Templates</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select one template from the list of provided mail
                    templates.
                </DialogContentText>
                <TextField
                    id="outlined-select-template"
                    select
                    label="Mail Templates"
                    value={template}
                    helperText="Please select your template"
                    variant="outlined"
                    onChange={handleChange}
                >
                    {templates.map((template) => (
                        <MenuItem
                            key={template.TemplateId}
                            value={template.Path}
                        >
                            {template.Name}
                        </MenuItem>
                    ))}
                </TextField>
                {download ? (
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ marginLeft: 50 }}
                        startIcon={<SaveIcon />}
                        href={download}
                        onClick={closeDialog}
                    >
                        Save
                    </Button>
                ) : null}
                {loading ? <CircularProgress /> : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    disabled={template === "" ? true : false}
                    onClick={generateMail}
                    color="primary"
                >
                    Generate Mail
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const SearchResults = (props) => {
    const [open, setOpen] = React.useState(false);
    const [templates, setTemplates] = React.useState([]);
    const [caseNumbers, setCaseNumbers] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    
    const state = {
        county: props.county,
        courtType: props.courtType,
        data: props.data
    };

    const handleClickOpen = (data) => {
        setCaseNumbers(data.map((caseNumber) => caseNumber.LeadId));
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        const fetchTemplates = async () => {
            const { data } = await template.getTemplates();
            setTemplates(data);
        };
        fetchTemplates();
    }, []);

    React.useEffect(() => {
        const countyColumns = SearchResultColumns.find(s => s.County === state.county && s.CourtType === state.courtType);
        setColumns(countyColumns ? countyColumns.Columns : [])
    }, [state.county, state.courtType]);

    return (
        <>
            {state.county && 
                <CustomizeTable
                    title="Leads"
                    columns={columns}
                    data={state.data}
                    isLoading={props.loading}
                    options={{
                        selection: true,
                        paging: true,
                        pageSize: 30,
                        pageSizeOptions: [30]
                    }}
                    actions={[
                        {
                            tooltip: "Generate Mail",
                            title: "Generate Mail",
                            icon: "mail",
                            onClick: (event, data) => handleClickOpen(data),
                        },
                    ]}
                />
            }
            {state.county && 
                <FormDialog
                    open={open}
                    handleClose={handleClose}
                    templates={templates}
                    caseNumbers={caseNumbers}
                    leadRole={props.leadRole}
                />
            }
        </>
    );
};

export default SearchResults;
