import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

export default function MaterialUIPickers(props) {
    const handleDateChange = (date) => {
        props.setSelectedDate(date);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
                <KeyboardDatePicker
                    format="MM/dd/yyyy"
                    margin="normal"
                    disableFuture
                    variant="inline"
                    id="date-picker-inline"
                    label={props.label}
                    value={props.selectedDate}
                    onChange={handleDateChange}
                    autoOk={true}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    className={props.className}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
}
