import http from "./httpService";
import { AWS } from "../constants/config";

const apiEndpoint = AWS.ServiceUrls.DashboardUser;

const getUsers = () => {
    return http.get(apiEndpoint);
};

const updateUser = (user) => {
    return http.put(apiEndpoint + user.UserId, user);
};

const deleteUser = (user) => {
    return http.delete(apiEndpoint + user.UserId);
};

const addUser = (user) => {
    return http.post(apiEndpoint, user);
};

export default {
    getUsers,
    updateUser,
    deleteUser,
    addUser,
};
