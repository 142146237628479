import http from "./httpService";
import { AWS } from '../constants/config';

const apiEndpoint = AWS.ServiceUrls.ResetPassword;

export async function resetPassCode(Username) {
    await http.post(apiEndpoint, { Username });
}

export async function confirmPassCode(Username, newPassword, verificationCode) {
    await http.put(apiEndpoint, { Username, newPassword, verificationCode });
}

export default {
    resetPassCode,
    confirmPassCode
}