import React, { useState } from "react";
import { Redirect, Link as RouterLink } from "react-router-dom";
import auth from "../../services/authService";
import resetPass from "../../services/resetPass";
import { useFormInput } from "../../hooks/inputHook";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Form from "../../components/Form";
import { validate, validateEmail } from "../../Utilities/validation";
import Input from "../../components/Input";
import { Routes } from "../../constants/config";

export default function ResetPassword() {
    const Email = useFormInput("");
    const Password = useFormInput("");
    const VerificationCode = useFormInput("");

    const [title, setTitle] = useState("Reset Password");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ severity: "info", message: null });
    const [show, setShow] = useState(false);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        const error = validateEmail(Email.value);
        if (error) {
            setError({ severity: "error", message: error.message });
            return;
        }

        setError({ ...error, message: null });
        setLoading(true);

        try {
            await resetPass.resetPassCode(Email.value);
            setShow(true);
            setTitle("Confirm Password");
            setError({
                severity: "info",
                message:
                    "Please check your email for your verification code, and enter a new password.",
            });
        } catch (ex) {
            setError({ severity: "error", message: ex.response.data.message });
        } finally {
            setLoading(false);
        }
    };

    const handleVerification = async (e) => {
        e.preventDefault();
        const error = validate(Email.value, Password.value);
        if (error) {
            setError({ severity: "error", message: error.message });
            return;
        }

        setError({ ...error, message: null });
        setLoading(true);

        try {
            await resetPass.confirmPassCode(
                Email.value,
                Password.value,
                VerificationCode.value
            );
            window.location = Routes.Login.Path;
        } catch (ex) {
            setError({ severity: "error", message: ex.response.data.message });
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        setShow(false);
        setTitle("Reset Password");
        Password.reset();
        VerificationCode.reset();
        Email.onChange(e);
    };

    if (auth.getCurrentUser()) return <Redirect to={Routes.Dashboard.Path} />;

    return (
        <Form
            title={title}
            alert={error}
            loading={loading}
            handleSubmit={(show && handleVerification) || handleResetPassword}
        >
            <Input
                label="Email"
                type="email"
                value={Email.value}
                onChange={handleChange}
            />

            {show && (
                <>
                    <Input
                        label="Password"
                        type="password"
                        value={Password.value}
                        onChange={Password.onChange}
                    />
                    <Input
                        label="Verification Code"
                        type="text"
                        value={VerificationCode.value}
                        onChange={VerificationCode.onChange}
                    />
                </>
            )}

            <Grid container>
                <Grid item xs>
                    <Link
                        component={RouterLink}
                        to={Routes.Login.Path}
                        variant="body2"
                    >
                        Return to Sign In
                    </Link>
                </Grid>
            </Grid>
        </Form>
    );
}
