import React, { useState } from "react";
import { useLocation, Redirect, Link as RouterLink } from "react-router-dom";
import auth from "../../services/authService";
import { useFormInput } from "../../hooks/inputHook";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { validate } from "../../Utilities/validation";
import Input from "../../components/Input";
import Logo from "../../assets/img/logo.png";

import Form from "../../components/Form";
import { Routes } from "../../constants/config";

export default function SignInSide() {
    let location = useLocation();

    const Username = useFormInput("");
    const Password = useFormInput("");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ severity: "error", message: null });

    const handleSignin = async (e) => {
        e.preventDefault();

        const validationError = validate(Username.value, Password.value);
        if (validationError) {
            setError({ ...error, message: validationError.message });
            return;
        }

        setError({ ...error, message: null });
        setLoading(true);

        try {
            await auth.signin(Username.value, Password.value);
            const { from } = location.state || {
                from: { pathname: Routes.Dashboard.Path },
            };
            window.location = from.pathname;
        } catch (ex) {
            setLoading(false);
            setError({ ...error, message: ex.response.data.message });
        }
    };

    if (auth.getCurrentUser()) return <Redirect to={Routes.Dashboard.Path} />;

    return (
        <>
            <Form
                title="Sign In"
                alert={error}
                loading={loading}
                handleSubmit={handleSignin}
            >
                <img src={Logo} width="250" alt="Legal Mail Leads Logo" />
                <p>
                    Please enter your credentials to login. If you need a login,
                    please contact your law firm administrator or contact us at{" "}
                    <a href="mailto:info@agilecodelab.com">
                        info@agilecodelab.com
                    </a>
                </p>
                <Input
                    label="Username"
                    type="email"
                    value={Username.value}
                    onChange={Username.onChange}
                />
                <Input
                    label="Password"
                    type="password"
                    value={Password.value}
                    onChange={Password.onChange}
                />

                <Grid container>
                    <Grid item xs>
                        <Link
                            component={RouterLink}
                            to={Routes.ResetPassword.Path}
                        >
                            Forgot password?
                        </Link>
                    </Grid>
                </Grid>
            </Form>
        </>
    );
}
