const SearchResultColumns = [
    {
        County: "Leon",
        CourtType: "Family Law",
        Columns: [
            { title: "CASE NUMBER", field: "CaseNumber", type: "string" },
            { title: "DATE FILED", field: "DateFiled" },
            { title: "CASE TYPE", field: "CaseType" },
            { title: "LAST NAME", field: "LastName" },
            { title: "FIRST NAME", field: "FirstName" },
            { title: "HAS ATTORNEY", field: "HasAttorney" },
            { title: "OPPOSING ATTORNEY", field: "OpposingPartyHasAttorney"}
        ]
    },
    {
        County: "Osceola",
        CourtType: "Family Law",
        Columns: [
            { title: "CASE NUMBER", field: "CaseNumber", type: "string" },
            { title: "DATE FILED", field: "DateFiled" },
            { title: "CASE TYPE", field: "CaseType" },
            { title: "LAST NAME", field: "LastName" },
            { title: "FIRST NAME", field: "FirstName" }
        ]
    },
    {
        County: "Orange",
        CourtType: "Family Law",
        Columns: [
            { title: "CASE NUMBER", field: "CaseNumber", type: "string" },
            { title: "DATE FILED", field: "DateFiled" },
            { title: "CASE TYPE", field: "CaseType" },
            { title: "LAST NAME", field: "LastName" },
            { title: "FIRST NAME", field: "FirstName" },
            { title: "HAS ATTORNEY", field: "HasAttorney" },
            { title: "OPPOSING ATTORNEY", field: "OpposingPartyHasAttorney"}
        ]
    },
    {
        County: "Orange",
        CourtType: "Criminal",
        Columns: [
            { title: "CASE NUMBER", field: "CaseNumber", type: "string" },
            { title: "DATE FILED", field: "DateFiled" },
            { title: "CASE TYPE", field: "CaseType" },
            { title: "LAST NAME", field: "LastName" },
            { title: "FIRST NAME", field: "FirstName" },
            { title: "ATTORNEY", field: "AttorneyName" },
            { title: "OFFENSE DATE", field: "OffenseDate" },
            { title: "CHARGE(S)", field: "Charge" },
            { title: "ARRESTING AGENCY", field: "ArrestAgency"}
        ]
    }
];

export default SearchResultColumns;