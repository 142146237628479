import http from "./httpService";
import { AWS } from "../constants/config";

const newUser = (Email, UserRole, LawFirmId) => {
    const data = {
        type: "SignUp",
        Username: Email,
        UserAttributes: [
            {
                Name: "custom:UserRole",
                Value: UserRole,
            },
        ],
    };
    if (LawFirmId) {
        data.UserAttributes.push({
            Name: "custom:FirmId",
            Value: LawFirmId,
        });
    }
    return http.post(AWS.ServiceUrls.SignUp, data);
};

const verifyAccount = (email, pw, code) => {
    const data = {
        type: "confirmSignUp",
        Username: email,
        Password: pw,
        ConfirmationCode: code
    };

    return http.post(AWS.ServiceUrls.VerifyAccount, data);
}

export default {
    newUser,
    verifyAccount
};
