export const AWS = {
    ServiceUrls: {
        SignUp: "https://cs1ayluyzd.execute-api.us-east-1.amazonaws.com/stage/SignUp",
        SignIn: "https://btguqaoxld.execute-api.us-east-1.amazonaws.com/Stage/SignIn/",
        ResetPassword: "https://hupye2p3fd.execute-api.us-east-1.amazonaws.com/Prod/ForgotPassword/",
        VerifyAccount: "https://oaxfdhe7w7.execute-api.us-east-1.amazonaws.com/Stage/CognitoAuth",
        DashboardUser: "https://nija5bttrd.execute-api.us-east-1.amazonaws.com/stage/LawUsers/",
        LawFirms: "https://4fep9jcoc4.execute-api.us-east-1.amazonaws.com/stage/LawFirms/",
        Leads: "https://ig12uzilc8.execute-api.us-east-1.amazonaws.com/Stage/SearhLeads",
        MailTemplates: {
            Dynamo: "https://qvvabbdvr5.execute-api.us-east-1.amazonaws.com/Stage/Templates/",
            S3: "https://nvxdqmfl7h.execute-api.us-east-1.amazonaws.com/stage/S3Upload",
        },
        Letter: "https://x5yxx5frg5.execute-api.us-east-1.amazonaws.com/stage/GenerateLetter",
    },
};

export const Routes = {
    Login: {
        Path: "/SignIn",
    },
    VerifyAccount: {
        Path: "/VerifyAccount"
    },
    ResetPassword: {
        Path: "/ResetPassword",
    },
    Dashboard: {
        Path: "/Dashboard",
    },
    Leads: {
        Path: "/Leads",
    },
    LawFirms: {
        Path: "/LawFirms",
    },
    Templates: {
        Path: "/LawFirms/:LawFirmId/Templates",
    },
    NotFound: {
        Path: "/not-found",
    },
    Users: {
        Path: "/LawFirms/:LawFirmId/ManageUsers",
    },
};

export const UserRoles = {
    NormalUser: "NormalUser",
    SuperAdmin: "SuperAdmin",
    Admin: "Admin",
};
