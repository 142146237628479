import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const Input = props => {

    const { label, type, value, onChange } = props;

    const [showPassword, setShowPassword] = useState(false);

    const inputType = type === "password" ? (showPassword ? 'text' : 'password') : type;

    return (
        <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel htmlFor={type}>{label}</InputLabel>
            <OutlinedInput
                id={type}
                type={inputType}
                value={value}
                onChange={onChange}
                label={label}
                name={type}
                autoComplete={type}
                required
                endAdornment={
                    type === "password" && <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
}

export default Input;